import React from "react";
import {
  Container,
  SimpleGrid,
  Center,
  Circle,
  Image,
  Heading,
  Text,
  Box,
  Stack,
  VStack,
} from "@chakra-ui/react";
import Banner from "components/banners/Banner.js";
import Blockchain from "assets/images/blockchain.png";
import Peer from "assets/images/peer.png";
import Limitless from "assets/images/limitless.png";
import Protection from "assets/images/protection.png";
import Pattern from "assets/images/bg-line.svg";

export default function About() {
  return (
    <>
      <Container
        maxW={"100%"}
        py={12}
        w="100%"
        background="#04040e"
        backgroundImage={{ sm: "none", md: `url(${Pattern})` }}
        backgroundSize="100% auto"
        backgroundRepeat="no-repeat"
      >
        <Banner />
        <Container maxW={"5xl"}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt="20">
            <Box>
              <Center width="100%" height="100%">
                <VStack>
                  <Box width="100%">
                    <Heading
                      textAlign="start"
                      as="h3"
                      fontSize={{ sm: "lg", md: "2xl" }}
                      fontFamily="Spantran"
                      color="white"
                    >
                      <Text color="secondary.500" as="span">Decentralized</Text>&nbsp;Trading For Everyone
                      
                    </Heading>
                  </Box>
                  <Box width="100%">
                    <Text
                      textAlign="justify"
                      fontSize={{ sm: "md", md: "lg" }}
                      fontFamily="ManropeLight"
                      color="white"
                    >
                     Scrowed is a cutting-edge decentralized peer-to-peer (P2P) platform that empowers users to buy cryptocurrencies with fiat or sell their crypto holdings securely. Our platform leverages smart contracts to manage the escrow process, ensuring that transactions are smooth, transparent, and secure. With support for major blockchain networks including Base and Binance Chains, Scrowed offers a versatile and reliable solution for all your crypto trading needs.
                    </Text>
                  </Box>
                </VStack>
              </Center>
            </Box>
            <Box>
              <SimpleGrid columns="2" spacing="5">
                <Box>
                  <VStack spacing="5">
                    <Box
                      boxShadow="lg"
                      background="brand.500"
                      width="100%"
                      height="180px"
                      borderRadius="15px"
                    >
                      <Center w="100%" height="100%">
                        <Stack>
                          <Box textAlign="center">
                            <Center>
                              <Image
                                src={Blockchain}
                                alt="blockchain"
                                width="70"
                                height="70"
                              />
                            </Center>
                            <Heading
                              mt="2"
                              color="white"
                              fontSize={{
                                base: "sm",
                                sm: "md",
                                md: "md",
                                lg: "lg",
                              }}
                              fontFamily="Spantran"
                              fontWeight="300"
                            >
                              Decentralized
                            </Heading>
                          </Box>
                        </Stack>
                      </Center>
                    </Box>
                    <Box
                      boxShadow="lg"
                      background="secondary.500"
                      width="100%"
                      height="180px"
                      borderRadius="15px"
                    >
                      <Center w="100%" height="100%">
                        <Stack>
                          <Box
                            textAlign="center"
                            fontWeight="bold"
                            color={"brand.500"}
                            fontSize={{
                              base: "sm",
                              sm: "sm",
                              md: "md",
                              lg: "lg",
                            }}
                            fontFamily="Spantran"
                          >
                            <Center>
                              <Image
                                src={Limitless}
                                alt="blockchain"
                                width="70"
                                height="70"
                              />
                            </Center>
                            <Heading
                              mt="2"
                              color={"white"}
                              fontSize={{
                                base: "sm",
                                sm: "md",
                                md: "md",
                                lg: "lg",
                              }}
                              fontFamily="Spantran"
                              fontWeight="300"
                            >
                              No Limit
                            </Heading>
                          </Box>
                        </Stack>
                      </Center>
                    </Box>
                  </VStack>
                </Box>
                <Box>
                  <VStack spacing="5" mt="20">
                    <Box
                      boxShadow="lg"
                      background="brand.500"
                      width="100%"
                      height="180px"
                      borderRadius="15px"
                    >
                      <Center w="100%" height="100%">
                        <Stack>
                          <Box textAlign="center">
                            <Center>
                              <Image
                                src={Peer}
                                alt="blockchain"
                                width="70"
                                height="70"
                              />
                            </Center>
                            <Heading
                              mt="2"
                              textAlign="center"
                              color="white"
                              fontSize={{
                                base: "sm",
                                sm: "md",
                                md: "md",
                                lg: "lg",
                              }}
                              fontFamily="Spantran"
                              fontWeight="300"
                            >
                              Peer-to-Peer
                            </Heading>
                          </Box>
                        </Stack>
                      </Center>
                    </Box>
                    <Box
                      boxShadow="lg"
                      background="secondary.500"
                      width="100%"
                      height="180px"
                      borderRadius="15px"
                    >
                      <Center w="100%" height="100%">
                        <Stack>
                          <Box textAlign="center">
                            <Center>
                              <Image
                                src={Protection}
                                alt="blockchain"
                                width="70"
                                height="70"
                              />
                            </Center>
                            <Heading
                              mt="2"
                              textAlign="center"
                              color={"white"}
                              fontSize={{
                                base: "sm",
                                sm: "md",
                                md: "md",
                                lg: "lg",
                              }}
                              fontFamily="Spantran"
                              fontWeight="200"
                            >
                              Simple & Safe
                            </Heading>
                          </Box>
                        </Stack>
                      </Center>
                    </Box>
                  </VStack>
                </Box>
              </SimpleGrid>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt="20" mb="10">
            <Box>
            </Box>
            <Box>
              <VStack>
                <Box width="100%">
                  <Heading
                    textAlign="start"
                    as="h3"
                    fontSize={{ sm: "lg", md: "2xl" }}
                    fontFamily="Spantran"
                    fontWeight="400"
                    color="white"
                  >
                   <Text color="secondary.500" as="span">Bridging</Text>&nbsp;the Crypto World with Fiat
                  </Heading>
                </Box>
                <Box width="100%">
                  <Text
                    textAlign="justify"
                    fontSize={{ sm: "md", md: "lg" }}
                    fontFamily="ManropeLight"
                    color="white"
                  >
                   Scrowed provides a straightforward process for users to buy cryptocurrencies using fiat currencies such as USD, EUR, Naira and more. Our platform supports multiple payment methods including bank transfers, paypal, skrill, and other popular payment options. Our efficient off-ramping process ensures that you can access your funds quickly and without hassle.
                  </Text>
                
                </Box>
              </VStack>
            </Box>
          </SimpleGrid>
        </Container>
      </Container>
    </>
  );
}
