import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Button,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useAccount, useChainId } from 'wagmi';
import { useSelector } from "react-redux";
import {
  CheckFaucetWithdrawal,
  Withdraw,
  ContractGetTokens,
  ContractFaucetContracts,
  ContractFaucetContractToken
} from "services/faucetService";
import NETWORKS from "contracts/Networks.json";
//const token = localStorage.getItem("authToken");

export default function Index() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const { isConnected, address } = useAccount();
  const chainId = useChainId();
  const network = chainId !== undefined ? NETWORKS[chainId] : null;

  const [amount, setAmount] = useState(500);
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState(null);
  const [asset, setAsset] = useState("");

  const getFaucetToken = async (asset) => {
    try {
      setAsset(asset);
      setLoading(true);
      let response = await CheckFaucetWithdrawal({ asset, chainId }, token);
      /*if (response.hasOwnProperty('success') && !response.success) {
        toast({
          title: response.message || "Please check back in 24 hours",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }*/
      await ContractGetTokens(asset);
      const faucetData = { asset, chainId, amount }
      response = await Withdraw(faucetData, token);
      setLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "You have been sent some Testnet DXP tokens",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }

    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getFaucetTokens = async () => {
    try {
      let tokenContracts = await ContractFaucetContracts();
      const results = await Promise.all(
        tokenContracts.map(async (contractAddress) => {
          try {
            const result = await ContractFaucetContractToken(contractAddress);
            return { contractAddress, ...result };
          } catch (tokenError) {
            console.error(`Error fetching token info for contract ${contractAddress}:`, tokenError);
            return { contractAddress, error: tokenError.message };
          }
        })
      );
      setTokens(results);
    } catch (error) { }
  };

  useEffect(() => {
    if (isConnected) {
      getFaucetTokens();
    }
  }, [isConnected]);


  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%">

        <Box width="100%" mt="5">
          <Box width="100%" mt="7" mb="7" textAlign="center">
            <Heading fontSize={{ sm: "md", lg: "xl" }} color="white">
              {" "}
              Faucet{" "}
            </Heading>
            <Text mt="3" fontSize={{ sm: "md", md: "lg" }} color="white">Obtain Testnet tokens every 24 hours </Text>
          </Box>
        </Box>

        <Center width="100%">
          <Card
            width={{ sm: "90%", md: "60%" }}
            background="brandScheme.500"
            p="4"
            minHeight="150"
            borderRadius="5"
          >

            <Center width="100%" mt="4">
              <Box width={{ sm: "100%", md: "80%" }} textAlign="center" mt="4">
                <SimpleGrid columns={{ sm: 1, md: 3 }} spacing="2">
                  <Button
                    colorScheme="secondary"
                    size="lg"
                    isLoading={asset === "USDT" && loading}
                    loadingText="Please Wait"
                    onClick={(e) => {
                      e.preventDefault();
                      getFaucetToken("USDT");
                    }}
                    borderRadius="3"
                  >
                    Get USDT
                  </Button>

                  <Button
                    colorScheme="secondary"
                    size="lg"
                    isLoading={asset === "BTC" && loading}
                    loadingText="Please Wait"
                    onClick={(e) => {
                      e.preventDefault();
                      getFaucetToken("BTC");
                    }}
                    borderRadius="3"
                  >
                    Get BTC
                  </Button>

                  <Button
                    colorScheme="secondary"
                    size="lg"
                    isLoading={asset === "ETH" && loading}
                    loadingText="Please Wait"
                    onClick={(e) => {
                      e.preventDefault();
                      getFaucetToken("ETH");
                    }}
                    borderRadius="3"
                  >
                    Get ETH
                  </Button>

                  {/*<Button
                    colorScheme="secondary"
                    size="lg"
                    isLoading={asset === "VDBX" && loading}
                    loadingText="Please Wait"
                    onClick={(e) => {
                      e.preventDefault();
                      getFaucetToken("VDBX");
                    }}
                    borderRadius="3"
                  >
                    Get VDBX
                  </Button>*/}

                </SimpleGrid>
              </Box>
            </Center>
          </Card>
        </Center>

        {
          tokens !== null && tokens.length > 0 &&
          tokens.map((token) => {
            return (
              <>
                {token.symbol !== "VDBX" &&
                  <Center width="100%" mt="5">
                    <Card
                      width={{ sm: "90%", md: "60%" }}
                      background="transparent"
                      borderWidth="1px"
                      borderColor="brand.500"
                      p="4"
                    >
                      <Center width="100%">
                        <Box width={{ sm: "100%", md: "100%" }} pl="3">
                          <Heading size="md" color="white">{token.name} Token Information</Heading>
                          <Text><Text as="span" color="white" fontWeight="bold">Network</Text><Text ml="2" as="span" color="white">{network !== null ? network.CHAINNAME : ""}</Text></Text>
                          <Text><Text as="span" color="white" fontWeight="bold">Contract Address</Text><Text ml="2" as="span" color="white">{token.contractAddress}</Text></Text>
                          <Text><Text as="span" color="white" fontWeight="bold">Symbol</Text><Text ml="2" as="span" color="white">{token.symbol}</Text></Text>
                          <Text><Text as="span" color="white" fontWeight="bold">Decimal</Text><Text ml="2" as="span" color="white">{network !== null ? "18" : ""}</Text></Text>
                        </Box>
                      </Center>
                    </Card>
                  </Center>}
              </>)
          })
        }
      </Box>
    </>
  );
}
