import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Center,
  Flex,
  Avatar,
  Button,
  NumberInput,
  NumberInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputRightAddon,
  InputGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  SimpleGrid,
  Select,
  Input,
  HStack,
  Stack,
  VStack,
  Skeleton,
  SkeletonText,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { GetBuyAd } from "services/adService";
import { CreateSellTrade, CreateSellTradeContract } from "services/tradeService";
import useWallet, { GetUserRatings } from "services/userService";
import { GetPaymentMethods } from "services/miscService";
import { GetUserPaymentMethods, CreateUserPaymentMethod } from "services/userService";
import { useSelector } from "react-redux";
import { useAccount, useChainId, useSwitchChain } from 'wagmi'
import NETWORKS from "contracts/Networks.json";
const { DateTime } = require("luxon");

export default function Index() {
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const navigate = useNavigate();
  const { adId } = useParams();

  const { isOpen: isCreateUserPaymentMethodOpen, onOpen: onCreateUserPaymentMethodOpen, onClose: onCreateUserPaymentMethodClose } = useDisclosure();
  const { isConnected, address } = useAccount();
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();
  const { GetTokenBalance } = useWallet();

  const toast = useToast();
  const [ad, setAd] = useState(null);

  const [loading, setLoading] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [buyUpdatedAdsLoading, setBuyUpdatedAdsLoading] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [createPaymentLoading, setCreatePaymentLoading] = useState(false);

  const [userId, setUserId] = useState(null);
  const [username, setUserName] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(12);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [finished, setFinished] = useState(false);
  const [reviews, setReviews] = useState([]);

  const [paymentMethods, setPaymentMethods] = useState(null);
  const [userPaymentMethods, setUserPaymentMethods] = useState(null);

  const [currentMethod, setCurrentMethod] = useState(null);
  const [currentMethodDetails, setCurrentMethodDetails] = useState([]);
  const [paymentView, setPaymentView] = useState("SelectPayment");

  const [sellData, setSellData] = useState({
    amount: "",
    advertID: "",
    unit: "",
    paymentMethod: "",
    unitFormatted: "",
    amountFormatted: "",
  });

  const [assetBalance, setAssetBalance] = useState(0);


  const handleGetBalance = async (asset) => {
    if (chainId !== undefined) {
      const balance = await GetTokenBalance(NETWORKS[chainId][asset], address);
      setAssetBalance(balance);
    }
  }

  const handleCreateSellTrade = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(sellData.amount) < parseFloat(ad.lowerLimit)) {
        toast({
          title: "Amount lesser than ad lower limit",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      else if (parseFloat(sellData.amount) > parseFloat(ad.upperLimit)) {
        toast({
          title: "Amount greater than ad upper limit",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      else if (parseFloat(sellData.unit) > truncateNumber(assetBalance, 2)) {
        toast({
          title: "Asset balance is lower than specified",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      else if (chainId.toString() !== ad.network) {
        handleSwitchNetwork(ad.network);
        return;
      }
      setBuyLoading(true);
      let response = await GetBuyAd(adId, token);
      setBuyLoading(false);
      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setAd(response.data);
      if (parseFloat(sellData.unit) > parseFloat(response.data.balance)) {
        toast({
          title: "Ad balance is lesser than specified",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.data.status !== "active") {
        toast({
          title: "Ad is not active",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }

      setBuyLoading(true);
      const sellTradeData = { paymentMethod: sellData.paymentMethod, unit: sellData.unit, buyer: ad.owner._id, advertId: adId };
      response = await CreateSellTrade(sellTradeData, token);

      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setBuyLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setBuyLoading(false);
        return;
      }

      const sellTradeContractData = { tradeId: response.result._id, advertId: adId, unit: response.result.unit["$numberDecimal"], asset: ad.asset.symbol }
      const tradeId = response.result._id;
      response = await CreateSellTradeContract(sellTradeContractData);
      if (response.success) {
        //update the buy trade funded status
        toast({
          title: "Trade initiated successfully, waiting for block confirmation",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setBuyLoading(false);
        navigate("/app/trades/sell/" + tradeId);
      }
    }
    catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setBuyLoading(false);
    }
  }

  const getAd = async () => {
    try {
      setLoading(true);
      const response = await GetBuyAd(adId, token);
      setLoading(false);
      setBuyUpdatedAdsLoading(false);
      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }

      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setAd(response.data);
        setUserId(response.data?.owner?._id);
        setUserName(response.data?.owner?.username);
      }
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      setBuyUpdatedAdsLoading(false);
    }
  };

  const getUserReviews = async () => {
    try {
      setRatingLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = { owner: userId };
      const response = await GetUserRatings(pagination, filter, token);
      setRatingLoading(false);
      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }

      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setReviews(response.data.ratings);
        setPageCount(response.data.count / limit);
        if (response.data?.ratings.length === 0) {
          setFinished(true);
        }
      }
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setRatingLoading(false);
    }
  };

  const handleSwitchNetwork = async (chainId) => {
    try {
      switchChain({ chainId: chainId });
    } catch (error) {
      console.error("Failed to switch network", error);
    }
  };

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetPaymentMethods();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setPaymentMethods(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [paymentData, setPaymentData] = useState({});
  const handleChange = (e, label) => {
    setPaymentData({
      ...paymentData,
      [label]: e.target.value,
    });
  };

  const handleCreatePaymentMethod = async () => {
    try {
      const userPaymentData = { ...paymentData, paymentMethod: currentMethod?._id }
      setCreatePaymentLoading(true);
      const response = await CreateUserPaymentMethod(userPaymentData, token);
      setCreatePaymentLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUserPaymentMethods();
        setPaymentView("SelectPayment");
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCreatePaymentLoading(false);
    }
  };

  const handleSelectChange = (e) => {
    const selectedMethod = paymentMethods.find(
      (method) => method._id === e.target.value
    );
    setCurrentMethod(selectedMethod);
    setCurrentMethodDetails(JSON.parse(selectedMethod.details));
  };

  const getUserPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetUserPaymentMethods(token);
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setUserPaymentMethods(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATE_MED);
  };

  const truncateNumber = (num, decimals) => {
    const factor = Math.pow(10, decimals);
    return Math.floor(num * factor) / factor;
  };

  useEffect(() => {
    if (isConnected && token !== null) {
      getAd();
      getPaymentMethods();
      getUserPaymentMethods();
    }
  }, [isConnected, token, adId])

  useEffect(() => {
    if (isConnected && token !== null && userId !== null) {
      getUserReviews();
    } else {
      setReviews([]);
      setPageCount(0);
    }
  }, [userId, token])

  useEffect(() => {
    if (!ad || !ad.asset.symbol) return;
    if (chainId === undefined) return;
    handleGetBalance(ad.asset.symbol);
  }, [chainId, ad]);

  useEffect(() => {
    if (isConnected && token) {
      const handleOpen = () => {
        socket.send(JSON.stringify({
          type: 'REGISTER',
          payload: {
            clientId: address.toLowerCase(),
          },
        }));
      };
      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'BUY_RATE_UPDATE_SUCCESS') {
          setBuyUpdatedAdsLoading(true);
          getAd();
        }
      };

      socket.onopen = handleOpen;
      socket.onmessage = handleMessage;
      // Cleanup function
      return () => {
        socket.onopen = null;
        socket.onmessage = null;
      };
    }
  }, [isConnected, token, getAd, setBuyUpdatedAdsLoading]);


  return (
    <>
      <Modal isOpen={isCreateUserPaymentMethodOpen} onClose={onCreateUserPaymentMethodClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent background="brand.500">
          <ModalHeader color="white">
            {paymentView === "CreatePayment" ? "Create Payment Method" : "Select Payment Option"}</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            {
              paymentView === "CreatePayment" &&
              <Box width="100%">
                <FormControl>
                  <FormLabel color={'white'} fontWeight="bold">
                    Payment Method
                  </FormLabel>
                  <Select
                    color="white"
                    placeholder="Select Payment Option"
                    onChange={handleSelectChange}
                  >
                    {paymentMethods !== null && paymentMethods.map((method) => <option key={method._id} value={method._id}>{method.name}</option>)}
                  </Select>
                </FormControl>

                {currentMethodDetails.map((detail) => (
                  <FormControl key={detail.label} mb={2} mt="4">
                    <FormLabel color="white" fontWeight="bold">
                      {detail.label}
                    </FormLabel>
                    <Input
                      color="white"
                      maxLength={100}
                      type={detail.type === 'number' ? 'number' : 'text'}
                      placeholder={detail.instruction}
                      onChange={(e) => handleChange(e, detail.label)}
                    />
                  </FormControl>
                ))}
              </Box>
            }

            {
              paymentView === "SelectPayment" && <Box width="100%">
                <FormControl>
                  <FormLabel color={'white'} fontWeight="bold">
                    <Flex justifyContent={"space-between"}>
                      <Text>Payment Option</Text>
                      <Text color="secondary.500" onClick={() => {
                        setPaymentView("CreatePayment");
                      }}>Create New</Text>
                    </Flex>
                  </FormLabel>
                  <Select
                    color="white"
                    required={true}
                    placeholder="Select Payment Option"
                    defaultValue={sellData.paymentMethod}
                    onChange={(e) => {
                      setSellData(prevSellData => ({
                        ...prevSellData,
                        paymentMethod: e.target.value,
                      }));
                    }}>
                    {
                      userPaymentMethods && userPaymentMethods.map((method) => {
                        // This should be changed to properties
                        let details = JSON.parse(method?.details);
                        let detailString = Object.entries(details)
                          .map(([key, value]) => details[key])
                          .join('*');

                        return (
                          <option value={method._id} key={method._id}>
                            {method?.paymentMethod?.name}(
                            {
                              detailString.endsWith('*') ? detailString.slice(0, -1) : detailString
                            })
                          </option>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Box>
            }
          </ModalBody>
          <ModalFooter>
            {
              paymentView === "CreatePayment" && (
                <>
                  <Button colorScheme="gray" mr={3} onClick={() => {
                    setPaymentView("SelectPayment");
                  }}>
                    Cancel
                  </Button>
                  <Button
                    background="secondary.500"
                    color="white"
                    _hover={{ color: "#000000", background: "#ffffff" }}
                    isLoading={createPaymentLoading} loadingText="Wait.." onClick={handleCreatePaymentMethod}>Add</Button>
                </>
              )
            }
            {
              paymentView === "SelectPayment" && (
                <>
                  <Button colorScheme="gray" mr={3} onClick={onCreateUserPaymentMethodClose}>
                    Cancel
                  </Button>
                  <Button
                    background="secondary.500"
                    color="white"
                    _hover={{ color: "#000000", background: "#ffffff" }}
                    isLoading={buyLoading}
                    loadingText="Wait..."
                    onClick={handleCreateSellTrade}>Proceed</Button>
                </>
              )
            }

          </ModalFooter>
        </ModalContent>
      </Modal>

      <Center width="100%">
        <Box width={{ sm: "100%", md: "60%" }}>
          {
            loading && !buyUpdatedAdsLoading ? <LoadingAd /> : (ad !== null ? (
              <>
                <Box width="100%"> <Heading color="white" size="md"> Offer #{adId} </Heading></Box>
                <VStack mt="5" background="brandScheme.500" padding="5" borderRadius={'5'}>
                  <Box width="100%">
                    <Flex>
                      <Heading
                        fontSize={{
                          base: "sm",
                          sm: "md",
                          md: "md",
                          lg: "lg",
                        }}
                        mt="2"
                        color="#ffffff">
                        Sell {ad.asset.symbol}
                      </Heading>
                      &nbsp;&nbsp;
                      {ad.network !== null && <Avatar src={NETWORKS[ad.network]['ICONURL']} size="sm" />}
                    </Flex>
                  </Box>
                  <Box width="100%">
                    <VStack width="100%" mt="5" spacing="2">
                      <Box width="100%" borderRadius={'10'} background="#000000" padding="5">
                        <FormControl>
                          <FormLabel color={'white'} fontWeight="bold">
                            I am selling
                          </FormLabel>
                          <InputGroup>
                            <NumberInput width="100%" borderColor="transparent" value={sellData.unitFormatted}>
                              <NumberInputField
                                color="white"
                                height="45"
                                placeholder="0.00"
                                borderColor="transparent"
                                borderRadius="0"
                                required={true}
                                onChange={(e) => {
                                  const amount = e.target.value === "" ? 0 : parseFloat(e.target.value.replace(/,/g, '')) * (ad.currency.rateToDollar["$numberDecimal"] * ad.asset.rateToDollar["$numberDecimal"] * (ad.factor["$numberDecimal"] / 100));
                                  setSellData(prevSellData => ({
                                    ...prevSellData,
                                    unit: e.target.value === "" ? "" : parseFloat(e.target.value.replace(/,/g, '')),
                                    unitFormatted: e.target.value === "" ? "" : e.target.value,
                                    amount: amount,
                                    amountFormatted: new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)
                                  }));
                                }}
                              />
                            </NumberInput>
                            <InputRightAddon textAlign="center" borderWidth="0" background="secondary.500" height="45" width="70px" borderRadius="0">{ad.asset.symbol}</InputRightAddon>
                          </InputGroup>
                          <FormHelperText>
                            <Flex justifyContent="space-between" width="100%">
                              <Text><b>Available Balance:</b>&nbsp;
                                {ad.asset.symbol !== "USDT" ? new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 6,
                                }).format(assetBalance) : new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(truncateNumber(assetBalance, 2))
                                }
                              </Text>
                              <Text>&nbsp;</Text>
                            </Flex>
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      <Box width="100%" borderRadius={'10'} background="#000000" padding="5">
                        <FormControl>
                          <FormLabel color={'white'} fontWeight="bold">
                            You will receive
                          </FormLabel>
                          <InputGroup>
                            <NumberInput
                              width="100%"
                              value={sellData.amountFormatted}>
                              <NumberInputField
                                color="white"
                                height="45"
                                borderColor="transparent"
                                borderRadius="0"
                                required={true}
                                onChange={(e) => {
                                  const unit = e.target.value === "" ? 0 : parseFloat(e.target.value.replace(/,/g, '')) / (ad.currency.rateToDollar["$numberDecimal"] * ad.asset.rateToDollar["$numberDecimal"] * (ad.factor["$numberDecimal"] / 100));
                                  setSellData(prevSellData => ({
                                    ...prevSellData,
                                    amount: e.target.value === "" ? "" : parseFloat(e.target.value.replace(/,/g, '')),
                                    amountFormatted: e.target.value === "" ? "" : parseFloat(e.target.value.replace(/,/g, '')),
                                    unit: unit,
                                    unitFormatted: ad.asset.symbol !== "USDT" ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 6 }).format(unit) : new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(unit)
                                  }));
                                }}
                              />
                            </NumberInput>
                            <InputRightAddon textAlign="center" borderWidth="0" background="secondary.500" height="45" width="70px" borderRadius="0">{ad.currency.symbol}</InputRightAddon>
                          </InputGroup>
                        </FormControl>
                      </Box>
                    </VStack>

                    <Box width="100%" textAlign={"center"} mt="5">
                      <Button
                        size="lg"
                        width={"100%"}
                        colorScheme="secondary"
                        borderRadius="10"
                        isLoading={buyLoading}
                        loadingText="Wait..."
                        onClick={onCreateUserPaymentMethodOpen}>
                        Proceed
                      </Button>
                    </Box>
                  </Box>
                </VStack>

                <SimpleGrid columns={{ base: 1, sm: 1, md: 4 }} width="100%" spacing={{ sm: "5", md: "2" }} p="5" mt="10" background="brandScheme.500" borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500">
                  <Box textAlign="left">
                    <Link to={"/app/user/" + ad.owner.username}>
                      <Heading textDecoration="underline" fontSize={{ sm: "sm", md: "md" }} color="white">{ad.owner.username}</Heading>
                    </Link>
                    <Flex color="gray.400" gap='1'>
                      <Text textAlign="left" fontSize={{ sm: "sm", md: "md" }}>
                        {ad.owner.completedTrades} Orders
                      </Text>
                      <Text> | </Text>
                      {
                        (() => {
                          const totalTrades = (ad.owner.completedTrades === 0 ? 1 : ad.owner.completedTrades) + ad.owner.incompleteTrades;
                          const percentageCompletion = ((ad.owner.completedTrades === 0 ? 1 : ad.owner.completedTrades) * 100) / totalTrades;
                          return (
                            <>
                              <Text fontSize={{ sm: "sm", md: "md" }}> {percentageCompletion}% Completion</Text>
                            </>
                          )
                        })()
                      }
                    </Flex>
                    <Flex color="gray.400" gap='1'>
                      <Text textAlign="left">
                        <Flex>
                          <Text fontSize={{ sm: "sm", md: "md" }}>{ad.owner.positiveTrades}</Text>&nbsp;<Text mt="1"><FiThumbsUp /></Text>
                        </Flex>
                      </Text>
                      <Text textAlign="left">
                        <Flex>
                          <Text fontSize={{ sm: "sm", md: "md" }}>{ad.owner.negativeTrades}</Text>&nbsp;<Text mt="1"><FiThumbsDown /></Text>
                        </Flex>
                      </Text>
                    </Flex>
                  </Box>
                  <Box textAlign="left">
                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                      {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ad.price["$numberDecimal"])} {ad.currency.symbol}
                    </Heading>
                    <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Price</Text>
                  </Box>

                  <Box textAlign="left">
                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                      {ad.balance} {ad.asset?.symbol}
                    </Heading>
                    <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Balance</Text>
                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white" mt="2">
                      {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(ad.lowerLimit)} - {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(ad.upperLimit)} {ad.currency.symbol}
                    </Heading>
                    <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Limit</Text>
                  </Box>
                  <Box textAlign="left">
                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">{ad.paymentMethod.name}</Heading>
                    <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Payment Method</Text>
                  </Box>
                </SimpleGrid>


                <Tabs variant='soft-rounded' minH="200" colorScheme='green' mt="10" p="5" borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500">
                  <TabList>
                    <Tab background="brand.500" color="white" fontSize={{ sm: "sm", md: "md" }}>Trade Instruction</Tab>
                    <Tab background="brand.500" ml="2" color="white" fontSize={{ sm: "sm", md: "md" }}>Trader's Review</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel p="0">
                      <Box width="100%" mt="5" mb="5">
                        <Heading color="white" fontSize={{ sm: "md", lg: "lg" }}>
                          Trade Instruction
                        </Heading>
                      </Box>
                      <Box width="100%" mt="2"> <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{ad.notes} </Text> </Box>
                    </TabPanel>

                    <TabPanel p="0">
                      <Box mt="5">
                        <Box width="100%"> <Heading color="white" fontSize={{ sm: "md", lg: "lg" }}> Trader's Review </Heading> </Box>
                        <Box width="100%" mt="3">
                          <VStack width="100%">
                            {
                              reviews !== null ? reviews.map((review) => (<><HStack spacing="3" width="100%"><TestimonialAvatar
                                src={""}
                                name={review.sender.username}
                              />
                                <Box>
                                  <Text fontSize={'sm'} color='gray.400'>
                                    {formatTime(review.createdAt)}
                                  </Text>
                                  <Text fontSize={'sm'} color='white'>
                                    {review.comment}
                                  </Text>
                                  <Box>
                                    <Rating rating={review.rating} />
                                  </Box>
                                </Box></HStack></>
                              )) :
                                <LoadingReview />
                            }
                          </VStack>
                          <Box mt="2" width="100%" textAlign="center" color="white" textDecoration={"underline"}><Link to={"/app/user/" + username}>See All Reviews</Link></Box>

                        </Box>
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </>) : "")
          }

          {(!isConnected || !token) && <Center width="100%" height="300">
            <Text fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }} color="#ffffff">Please Connect Wallet</Text>
          </Center>
          }
        </Box>
      </Center>
    </>
  );
}

const TestimonialAvatar = ({
  src,
  name
}) => {
  return (
    <Flex align={'center'} mt={4} direction={'column'}>
      <Avatar src={''} alt={name} mb={2} name={name} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={400} color="white" textDecoration={"underline"}>{name}</Text>
      </Stack>
    </Flex>
  );
};

const Rating = ({ rating }) => {
  const filledStars = Math.floor(rating);
  const hasHalfStar = rating - filledStars >= 0.5;

  return (
    <Flex>
      {[...Array(5)].map((_, index) => (
        <Box key={index} color={index < filledStars ? "yellow.400" : "gray.600"}>
          {index < filledStars ? (
            <StarIcon boxSize={4} />
          ) : (
            hasHalfStar && index === filledStars && <StarIcon boxSize={4} />
          )}
        </Box>
      ))}
    </Flex>
  );
};

const LoadingAd = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};

const LoadingReview = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="5"
        mb="5"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
