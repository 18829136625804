import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  FormControl,
  FormLabel,
  Stack,
  Button,
  RadioGroup,
  Radio,
  SimpleGrid,
  VStack,
  HStack,
  Skeleton,
  SkeletonText,
  useToast,
  useMediaQuery
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { GetAssets, GetCurrencies, GetPaymentMethods } from "services/miscService";
import SellAd from "./components/SellAdOffer.js";
import Paginate from "components/navigation/Paginate.js";
import { GetSellAds } from "services/adService";
import { useAccount, useChainId } from 'wagmi';
import { useSelector } from "react-redux";
import { AiFillNotification } from "react-icons/ai";
import { FaCreditCard } from "react-icons/fa";
import { ChevronDownIcon } from '@chakra-ui/icons';

export default function Index() {
  const toast = useToast();
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const { isConnected, address } = useAccount();
  const chainId = useChainId();

  const [sellAdsLoading, setSellAdsLoading] = useState(false);
  const [sellUpdatedAdsLoading, setSellUpdatedAdsLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [assets, setAssets] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [buyAds, setBuyAds] = useState(null);
  const [sellAds, setSellAds] = useState(null);
  
  const [loading, setLoading] = useState(false);
  const [sellPageCount, setSellPageCount] = useState(0);
  const [sellSortOrder, setSellSortOrder] = useState("desc");
  const [sellFrom, setSellFrom] = useState(0);
  const [sellLimit, setSellLimit] = useState(10);
  const [sellOrderBy, setSellOrderBy] = useState("createdAt");
  const [sellFinished, setSellFinished] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);

  const [isMdOrSm] = useMediaQuery("(max-width: 960px)");

  const getSellAds = async () => {
    try {
      setSellAdsLoading(true);
      const pagination = {
        from: sellFrom,
        limit: sellLimit,
        orderBy: sellOrderBy,
        sortOrder: sellSortOrder,
      };
      const filter = { currency: selectedCurrency, asset: selectedAsset, paymentMethod: selectedMethod };
      const response = await GetSellAds(filter, pagination, token);
      setSellAdsLoading(false);
      setSellUpdatedAdsLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setSellAds(response.data.ads);
        setSellPageCount(response.data.count / sellLimit);
        return;
      }
    } catch (error) {
      setSellAdsLoading(false);
      setSellUpdatedAdsLoading(false);
    }
  };

  const getCurrencies = async () => {
    try {
      setLoading(true);
      const response = await GetCurrencies();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        const currency = response.data[0];
        setSelectedCurrency(localStorage.getItem("currency") ? localStorage.getItem("currency") : currency._id);
        setCurrencies(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAssets = async () => {
    try {
      setLoading(true);
      const response = await GetAssets();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        const asset = response.data[0];
        setSelectedAsset(localStorage.getItem("asset") ? localStorage.getItem("asset") : asset._id);
        setAssets(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const response = await GetPaymentMethods();
      setLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        const paymentMethod = response.data[0];
        setSelectedMethod(localStorage.getItem("paymentMethod") ? localStorage.getItem("paymentMethod") : paymentMethod._id);
        setPaymentMethods(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [paymentData, setPaymentData] = useState({});
  const handleChange = (e, label) => {
    setPaymentData({
      ...paymentData,
      [label]: e.target.value,
    });
  };

  const handleCurrencyChange = (value) => {
    localStorage.setItem("currency", value);
    setSelectedCurrency(value);
  };

  const handleAssetChange = (value) => {
    localStorage.setItem("asset", value);
    setSelectedAsset(value);
  };

  const handleMethodChange = (value) => {
    localStorage.setItem("paymentMethod", value);
    setSelectedMethod(value);
  };

  const clearFilters = () => {
    setSelectedCurrency("");
    setSelectedAsset("");
    getSellAds();
  };

  useEffect(() => {
    getAssets();
    getCurrencies();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (selectedCurrency !== null && selectedAsset !== null && selectedMethod !== null) {
      getSellAds();
    }
  }, [selectedCurrency, selectedAsset, selectedMethod]);

  useEffect(() => {
    if (isConnected && token) {
      const handleOpen = () => {
        socket.send(JSON.stringify({
          type: 'REGISTER',
          payload: {
            clientId: address.toLowerCase(),
          },
        }));
      };
      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'SELL_RATE_UPDATE_SUCCESS') {
          setSellUpdatedAdsLoading(true);
          getSellAds();
        }
      };

      socket.onopen = handleOpen;
      socket.onmessage = handleMessage;
      // Cleanup function
      return () => {
        socket.onopen = null;
        socket.onmessage = null;
      };
    }
  }, [isConnected, token, getSellAds, setSellUpdatedAdsLoading]);


  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} p="3" width="100%">
        <Box width="100%" mt="5" mb="5">
          <Heading color="white" fontSize={{ sm: "md", lg: "xl" }}>
            Find Offers
          </Heading>
        </Box>

        <SimpleGrid columns={{ sm: 1, md: 2 }}>
          <Box maxWidth="190px" mb="5">
            <Stack width="100%" direction='row' spacing={2} borderRadius="3" borderWidth="1px" borderColor="secondary.500" padding="1">
              <Link to="/app/ads">
                <Button colorScheme='default' variant='solid' borderRadius="3" width="87px" fontSize={{ sm: "sm", md: "md" }}>
                  Sell
                </Button>
              </Link>
              <Link to="/app/ads/selloffers">
                <Button colorScheme='secondary' borderRadius="3" width="87px" fontSize={{ sm: "sm", md: "md" }}>
                  Buy
                </Button>
              </Link>
            </Stack>
          </Box>
          <Box textAlign="right">
            <Menu>
              <MenuButton rightIcon={<ChevronDownIcon />} color="white" fontSize={{ sm: "sm", md: "md" }}>
                P2P Center <ChevronDownIcon />
              </MenuButton>
              <MenuList background="brand.500">
                <Link to="/app/ads/user">
                  <MenuItem color="white" _focus={{ background: "transparent" }} fontSize={{ sm: "sm", md: "md" }}><AiFillNotification /> &nbsp; Manage Offers</MenuItem>
                </Link>
                <Link to="/app/profile/payment">
                  <MenuItem color="white" _focus={{ background: "transparent" }} fontSize={{ sm: "sm", md: "md" }}> <FaCreditCard /> &nbsp; Manage Payment Methods</MenuItem>
                </Link>
              </MenuList>
            </Menu>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          columns={{ sm: 2, md: 2, lg: 4 }}
          w="100%"
          spacing="5"
          mt="10"
          mb="10">
          <Box>
            <FormControl>
              <FormLabel fontWeight="bold" color="white">Assets</FormLabel>
              <Menu>
                <MenuButton textAlign="left" as={Button} width="100%" rightIcon={<ChevronDownIcon />} borderRadius={"3px"} background="secondary.500" border={"none"} fontSize={{ sm: "sm", md: "md" }}>
                  {selectedAsset !== "" & assets.length > 0 ? assets.find(asset => asset._id === selectedAsset).symbol : 'Select Option'}
                </MenuButton>
                <MenuList maxH="200px" overflowY="auto" background="brandScheme.500">
                  <RadioGroup onChange={handleAssetChange} value={selectedAsset}>
                    <Stack spacing={1} p={2}>
                      {assets.length > 0 && assets.map((asset) => (
                        <MenuItem key={asset._id} width="100%" color="white" _hover={{ background: "brand.500" }} _focus={{ background: "transparent" }}>
                          <Radio value={asset._id} width="100%" color="white" fontSize={{ sm: "sm", md: "md" }}>
                            {asset.symbol}
                          </Radio>
                        </MenuItem>
                      ))}
                    </Stack>
                  </RadioGroup>
                </MenuList>
              </Menu>
            </FormControl>
          </Box>

          <Box>
            <FormControl>
              <FormLabel fontWeight="bold" color="white">Currency</FormLabel>
              <Menu>
                <MenuButton textAlign="left" as={Button} width="100%" rightIcon={<ChevronDownIcon />} borderRadius={"3px"} background="secondary.500" border={"none"} fontSize={{ sm: "sm", md: "md" }}>
                  {selectedCurrency !== "" && currencies.length > 0 ? currencies.find(currency => currency._id === selectedCurrency).symbol : 'Select Option'}
                </MenuButton>
                <MenuList maxH="200px" overflowY="auto" background="brandScheme.500">
                  <RadioGroup background="brandScheme.500" onChange={handleCurrencyChange} value={selectedCurrency}>
                    <Stack spacing={1} p={2} background="brandScheme.500">
                      {currencies.length > 0 && currencies.map((currency) => (
                        <MenuItem key={currency._id} width="100%" color="white" _hover={{ background: "brand.500" }} _focus={{ background: "transparent" }}>
                          <Radio value={currency._id} width="100%" fontSize={{ sm: "sm", md: "md" }}>
                            {currency.symbol}
                          </Radio>
                        </MenuItem>
                      ))}
                    </Stack>
                  </RadioGroup>
                </MenuList>
              </Menu>
            </FormControl>
          </Box>

          <Box>
            <FormControl>
              <FormLabel fontWeight="bold" color="white">Payment Method</FormLabel>
              <Menu>
                <MenuButton textAlign="left" as={Button} width="100%" rightIcon={<ChevronDownIcon />} borderRadius={"3px"} background="secondary.500" border={"none"} fontSize={{ sm: "sm", md: "md" }}>
                  {selectedMethod !== "" && paymentMethods.length > 0 ? paymentMethods.find(method => method._id === selectedMethod).name : 'Select Option'}
                </MenuButton>
                <MenuList maxH="200px" overflowY="auto" background="brandScheme.500">
                  <RadioGroup onChange={handleMethodChange} value={selectedMethod}>
                    <Stack spacing={1} p={2}>
                      {paymentMethods.length > 0 && paymentMethods.map((method) => (
                        <MenuItem key={method._id} width="100%" color="white" _hover={{ background: "brand.500" }} _focus={{ background: "transparent" }}>
                          <Radio value={method._id} width="100%" color="white" fontSize={{ sm: "sm", md: "md" }}>
                            {method.name}
                          </Radio>
                        </MenuItem>
                      ))}
                    </Stack>
                  </RadioGroup>
                </MenuList>
              </Menu>
            </FormControl>
          </Box>


          <Box>
            <FormLabel fontWeight="bold">&nbsp;</FormLabel>
            <HStack width="100%">
              <Button
                maxWidth={"8em"}
                color="#ffffff"
                _hover={{ color: "#ffffff", background: "brand.500" }}
                background={"secondary.500"}
                borderRadius={"3px"}
                fontSize={{ sm: "sm", md: "md" }}
                onClick={() => {
                  getSellAds();
                }}
              >
                Find
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="outline"
                maxWidth={"8em"}
                color="white"
                _hover={{ color: "#ffffff", background: "brandScheme.500" }}
                borderRadius={"3px"}
                fontSize={{ sm: "sm", md: "md" }}
                onClick={clearFilters}
              >
                Reset
              </Button>
            </HStack>
          </Box>
        </SimpleGrid>


        <Box width="100%" mt="10" mb="3">
          <Heading fontSize={{ sm: "md", md: "xl" }} color="secondary.500">
            Sell Offers
          </Heading>
        </Box>
        {sellAdsLoading && !sellUpdatedAdsLoading ? (
          <LoadingAds />
        ) : (
          <>
            {!isMdOrSm && (
              <HStack width="100%">
                <Box width={{ sm: "85%", md: "90%" }} p="5" pb="0">
                  <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} width="100%" spacing="2">
                    <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Merchant</Text></Box>
                    <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Price</Text></Box>
                    <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Available Order | Limit</Text></Box>
                    <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Payment Method</Text></Box>
                  </SimpleGrid>
                </Box>
                <Box width={{ sm: "15%", md: "10%" }} p="5" pb="0">
                </Box>
              </HStack>
            )}
            <VStack
              width="100%"
              mt="3"
              mb="3"
              spacing="5"
            >
              {
                sellAds !== null && sellAds.length > 0 ? sellAds.map((ad) => <SellAd key={ad._id} ad={ad} />) : <Flex> <AiFillNotification color="white" size="25" /> &nbsp; <Text align="center" color="white">  No sell ads yet</Text> </Flex>
              }
            </VStack>
          </>
        )}

        {sellPageCount > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={sellPageCount}
              setFrom={setSellFrom}
              getRecords={getSellAds}
            />
          </Box>
        )}

      </Box>
    </>
  );
}

const LoadingAds = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
