import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import "assets/css/App.css";
import WebLayout from "layouts/web";
import AdminLayout from "layouts/admin";
import UserLayout from "layouts/user";
import WebAuthLayout from "layouts/webauth";
import routes from "routes.js";
import routesuser from "routesuser.js";
import Home from "views/web/home";
import Login from "views/auth";
import Terms from "views/web/terms";
import Aml from "views/web/aml";
import Privacy from "views/web/privacy";
import UpdateAd from "views/app/ads/update";
import Ads from "views/app/ads";
import BuyOffers from "views/app/ads/buyoffers";
import SellOffers from "views/app/ads/selloffers";
import SellOffer from "views/app/ads/selloffer";
import BuyOffer from "views/app/ads/buyoffer";
import Trade from "views/app/trades/trade";
import Profile from "views/app/profile";
import Payment from "views/app/profile/payment";
import User from "views/app/user";
import Staking from "views/app/staking";
import Faucet from "views/app/faucet";
import { setUser } from "utils/userSlice";
import { UserLogin } from "services/userService";
import { useSelector, useDispatch, Provider } from "react-redux";

import { WagmiProvider, useAccount, useSwitchChain, useChainId, useConnect, useDisconnect } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from 'variables/connector';
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "utils/store";
import useLastActive from 'hooks/useLastActive';


const queryClient = new QueryClient();

function App() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/app" && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if ((prop.layout === "/admin") && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if ((prop.layout === "/admin") && prop.collapse) {
        return (
          <Route
            path={prop.path + "/*"}
            element={prop.component}
            key={key}
          >
            <Route path="*" element={<Navigate to={prop.layout + "/" + prop.path + "/index"} replace />} />
            {getRoutes(prop.items)}
          </Route>
        );
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const WebRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/web/index" replace />} />
        <Route path="/web/*" element={<WebLayout />}>
          <Route path="index" element={<Home />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="aml" element={<Aml />} />
          <Route path="*" element={<Navigate to="/web/index" replace />} />
        </Route>
      </Routes>
    );
  };

  const AuthRoutes = () => {
    return (
      <Routes>
        <Route path="/auth/*" element={<WebAuthLayout />}>
          <Route path="index" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Navigate to="/auth/index" replace />} />
        </Route>
      </Routes>
    );
  };

  const UserRoutes = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const { isConnected, address } = useAccount();
    const chainId = useChainId();
    const { disconnect } = useDisconnect();
    const { connectors, connect } = useConnect();
    const { switchChain } = useSwitchChain();

    const user = useSelector((state) => state?.user?.value);
    const isLoggedIn = user.hasOwnProperty("token") ? true : false;
    useLastActive(user.token);

    const [loginLoading, setLoginLoading] = useState(false);

    const handleConnect = async (connector, chainId) => {
      try {
        localStorage.setItem("chainId", chainId);
        connect({ connector, chainId });
      } catch (error) {
        console.error("Failed to connect to the chain:", error);
      }
    };

    const handleSwitchNetwork = async (chainId) => {
      if(!isConnected) {
        console.log("Wallet not connected");
        return;
      }
      try {
        switchChain({ chainId: chainId });
      } catch (error) {
        console.error("Failed to switch network", error);
      }
    };

    const handleLogout = async () => {
      dispatch(setUser({}));
      disconnect();
      //await close();
    };

    const handleLogin = async (address) => {
      if (isLoggedIn) return false;
      try {
        setLoginLoading(true);
        const response = await UserLogin(address);
        setLoginLoading(false);
        if (response.error) {
          toast({
            title: response.error || "An error occured",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          handleLogout();
          return;
        }
        if (!response.success) {
          toast({
            title: response.message || "An error occured",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          handleLogout();
          return;
        }
        if (response.success) {
          if (address !== undefined && address.toLowerCase() !== response.data.address.toLowerCase()) {
            toast({
              title: "Invalid login",
              status: "error",
              duration: 9000,
              position: "top-right",
              isClosable: true,
            });
            return;
          }
          dispatch(setUser(response.data));
        }
      } catch (error) {
        setLoginLoading(false);
        toast({
          title: error.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        handleLogout();
      }
    };


    useEffect(() => {
       const checkConnection = () => {
         if (isConnected) {
           handleLogin(address);
           if (parseInt(chainId) !== parseInt(localStorage.getItem("chainId"))) {
             handleSwitchNetwork(parseInt(localStorage.getItem("chainId")));
           }
         } else {
           handleLogout();
         }
       };
       // Adding a timeout to ensure the state is fully updated
       const timeoutId = setTimeout(checkConnection, 700);
       return () => clearTimeout(timeoutId);
     }, [isConnected]);


    return (
      <Routes>
        <Route path="/app/*" element={<UserLayout handleLogin={handleLogin} handleLogout={handleLogout} loginLoading={loginLoading} handleConnect={handleConnect} isConnected={isConnected} address={address} />}>
          {getRoutes(routesuser)}
          {/*<Route path="match/:matchId/:teamId" element={<Match />} />*/}
          <Route path="ads/index" element={<BuyOffers />} />
          <Route path="ads/selloffers" element={<SellOffers />} />
          <Route path="ads/user" element={<Ads />} />
          <Route path="ads/edit/:type/:adId" element={<UpdateAd />} />
          <Route path="ads/selloffer/:adId" element={<SellOffer />} />
          <Route path="ads/buyoffer/:adId" element={<BuyOffer />} />
          <Route path="trades/:type/:tradeId" element={<Trade />} />
          <Route path="*" element={<Navigate to="/app/ads" replace />} />
          <Route path="profile" element={<Profile />} />
          <Route path="profile/payment" element={<Payment />} />
          <Route path="profile/:code" element={<Profile />} />
          <Route path="user/:username" element={<User />} />
          <Route path="staking" element={<Staking />} />
          <Route path="faucet" element={<Faucet />} />
        </Route>
        <Route path="/admin/*" element={<AdminLayout />}>
          {getRoutes(routes)}
        </Route>
      </Routes>
    );
  };

  return (
    <WagmiProvider config={config} reconnectOnMount={true}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <WebRoutes />
              <AuthRoutes />
              <UserRoutes />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
