import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Flex,
  Icon,
  Text,
  useClipboard,
  SkeletonText
} from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { useAccount, useChainId, useBalance } from 'wagmi';
import useWallet from "services/userService";
import NETWORKS from "contracts/Networks.json";
import Card from "components/card/Card";


export default function Index() {
  const [value, setValue] = useState('');
  const { onCopy, hasCopied } = useClipboard(value);
  const { address } = useAccount();
  const chainId = useChainId();
  const { GetTokenBalance } = useWallet();

  const [balances, setBalances] = useState([
    { asset: "BTC", balance: null },
    { asset: "USDT", balance: null },
    { asset: "ETH", balance: null },
    //{ asset: "VDBX", balance: null },
  ]);

  const getTokenBalance = async () => {
    try {
      const usdtBalance = await GetTokenBalance(NETWORKS[chainId]["USDT"], address);
      const btcBalance = await GetTokenBalance(NETWORKS[chainId]["BTC"], address);
      const ethBalance = await GetTokenBalance(NETWORKS[chainId]["ETH"], address);
      const wallets = [
        { asset: "BTC", balance: btcBalance },
        { asset: "USDT", balance: usdtBalance },
        { asset: "ETH", balance: ethBalance },
       // { asset: "VDBX", balance: vdbxBalance },
      ];
      setBalances(wallets);
    } catch (error) { 
      console.log(error);
    }
  };

  useEffect(() => {
    if (address && chainId) {
      getTokenBalance();
    }
  }, [address, chainId]);

  useEffect(() => {
    if (value === "") return;
    onCopy();
  }, [value]);


  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" pb="20px" p="4">
        <Box width="100%" mt="5" mb="5">
          <Heading color="white" size="md">
            Wallet
          </Heading>
        </Box>

        <SimpleGrid
          columns={{ base: 2, sm: 2, md: 3, lg: 3 }}
          spacing={2}
          mb="4">
          {
            balances.map((balance) => {
              return (
                <Box minHeight="90" className="trade-record-card" background="brandScheme.500">
                  <Box minHeight="90" width="100%" pt="3">
                    <Heading color="white" textAlign="center" fontSize={{ sm: "md", md: "lg" }}>
                      {balance.asset}
                    </Heading>
                    <Text color="white" fontWeight="bold" textAlign="center" fontSize={{ sm: "sm", md: "md" }}>
                      {balance.balance !== null ? (balance.asset !== "USDT" ? new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 6,
                      }).format(balance.balance) : new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(balance.balance)) : <Center><SkeletonText mt="2" noOfLines={1} skeletonHeight='2' width="25px"></SkeletonText></Center>
                      }
                    </Text>
                  </Box>
                </Box>)
            })
          }

        </SimpleGrid>

        {
          balances !== null &&
          <Center mt="5">
            <Card background="transparent" borderColor="brand.500" borderWidth="1px" width={{ sm: "100%", md: "70%", lg: "50%" }} minH="200">
              <Text fontWeight="bold" mt="5" color="white" fontSize={{ sm: "md", md: "lg" }} textAlign={"center"}>Your Wallet Address</Text>
              <Center mt="5">
                <Flex><Text color="white" fontSize={{ sm: "md", md: "lg" }}>{address !== null && address !== undefined ? address : <Center><SkeletonText mt="2" noOfLines={1} skeletonHeight='2' width="100px"></SkeletonText></Center>}</Text> &nbsp; &nbsp;

                  {address !== undefined && <Icon mt="1" width="18" height="18" color="white" as={FiCopy} cursor="pointer"
                    onClick={() => {
                      setValue(address);
                    }} />}&nbsp; &nbsp;
                  {address !== undefined && <Text mt="1" color="white" fontSize={"xs"}>{hasCopied ? 'Copied!' : ''}</Text>}
                </Flex>
              </Center>
            </Card>
          </Center>
        }

      </Box>
    </>
  );
}