import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Center,
  Flex,
  Avatar,
  Button,
  NumberInput,
  NumberInputField,
  InputRightAddon,
  InputGroup,
  FormControl,
  FormLabel,
  SimpleGrid,
  HStack,
  Stack,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Skeleton,
  SkeletonText,
  useToast
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { GetSellAd } from "services/adService";
import { CreateBuyTrade, CreateBuyTradeContract, MarkFundedBuyTrade } from "services/tradeService";
import { GetUserRatings } from "services/userService";
import { useAccount, useChainId, useSwitchChain } from 'wagmi'
import { useSelector } from "react-redux";
import NETWORKS from "contracts/Networks.json";
import { connections } from "variables/connections";
const { DateTime } = require("luxon");


export default function Index() {
  const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const navigate = useNavigate();
  const { adId } = useParams();

  const { isConnected, address } = useAccount();
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();

  const toast = useToast();
  const [ad, setAd] = useState(null);

  const [loading, setLoading] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);
  const [sellUpdatedAdsLoading, setSellUpdatedAdsLoading] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);

  const [userId, setUserId] = useState(null);
  const [username, setUserName] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(12);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [finished, setFinished] = useState(false);
  const [reviews, setReviews] = useState([]);

  const [buyData, setBuyData] = useState({
    amount: 0,
    advertID: "",
    unit: 0,
    unitFormatted: 0,
    amountFormatted: 0
  });

  const handleCreateBuyTrade = async () => {
    try {
      if (!isConnected) {
        toast({
          title: "Please connect wallet",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(buyData.amount) < parseFloat(ad.lowerLimit)) {
        toast({
          title: "Amount lesser than ad lower limit",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      else if (parseFloat(buyData.amount) > parseFloat(ad.upperLimit)) {
        toast({
          title: "Amount greater than ad upper limit",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      else if (chainId.toString() !== ad.network) {
        handleSwitchNetwork(ad.network);
        return;
      }
      setSellLoading(true);
      let response = await GetSellAd(adId, token);
      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setAd(response.data);
      if (parseFloat(buyData.unit) > parseFloat(response.data.balance)) {
        toast({
          title: "Ad balance is lesser than specified",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setSellLoading(false);
        return;
      }
      if (response.data.status !== "active") {
        toast({
          title: "Ad is not active",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setSellLoading(false);
        return;
      }

      setSellLoading(true);
      let buyTradeData = { unit: buyData.unit, seller: ad.owner._id, advertId: adId };
      response = await CreateBuyTrade(buyTradeData, token);
      setSellLoading(false);
      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setSellLoading(true);
      const buyTradeContractData = { unit: response.result.unit["$numberDecimal"], tradeId: response.result._id, advertId: adId }
      const tradeId = response.result._id;
      response = await CreateBuyTradeContract(buyTradeContractData);

      if (response.success) {
        //update the buy trade funded status
        buyTradeData = { transactionHash: response.transactionHash, chainId };
        response = await MarkFundedBuyTrade(buyTradeData, tradeId, token);

        if (!response || !response?.success) {
          toast({
            title: "An error occured on the blockchain",
            status: "error",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          setSellLoading(false);
          return;
        }
        setSellLoading(false);
        navigate("/app/trades/buy/" + tradeId);
      }
    }
    catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSellLoading(false);
    }
  }

  const getAd = async () => {
    try {
      setLoading(true);
      const response = await GetSellAd(adId, token);
      setLoading(false);
      setSellUpdatedAdsLoading(false);
      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setAd(response.data);
        setUserId(response.data?.owner?._id);
        setUserName(response.data?.owner?.username);
      }
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      setSellUpdatedAdsLoading(false);
    }
  };

  const getUserReviews = async () => {
    try {
      setRatingLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = { owner: userId };
      const response = await GetUserRatings(pagination, filter, token);
      setRatingLoading(false);
      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setReviews(response.data.ratings);
        setPageCount(response.data.count / limit);
        if (response.data?.ratings.length === 0) {
          setFinished(true);
        }
      }
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setRatingLoading(false);
    }
  };

  const handleSwitchNetwork = async (chainId) => {
    try {
      switchChain({ chainId: chainId });
    } catch (error) {
      console.error("Failed to switch network", error);
    }
  };

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATE_MED);
  };

  const truncateNumber = (num, decimals) => {
    const factor = Math.pow(10, decimals);
    return Math.floor(num * factor) / factor;
  };

  useEffect(() => {
    if (isConnected && token !== null) getAd();
  }, [token, isConnected])

  useEffect(() => {
    if (isConnected && token) {
      const handleOpen = () => {
        console.log('WebSocket connection established');
        socket.send(JSON.stringify({
          type: 'REGISTER',
          payload: {
            clientId: address.toLowerCase(),
          },
        }));
      };

      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'TRADE_OPENED_SUCCESS') {
          setSellLoading(false);
          navigate("/app/trades/buy/" + message.payload.tradeId);
        }
      };

      socket.onopen = handleOpen;
      socket.onmessage = handleMessage;
      // Cleanup function
      return () => {
        socket.onopen = null;
        socket.onmessage = null;
      };
    }
  }, [isConnected, token, address, navigate]);

  useEffect(() => {
    if (token !== null && userId !== null) {
      getUserReviews();
    } else {
      setReviews([]);
      setPageCount(0);
    }
  }, [userId, token])

  useEffect(() => {
    if (isConnected && token) {
      const handleOpen = () => {
        socket.send(JSON.stringify({
          type: 'REGISTER',
          payload: {
            clientId: address.toLowerCase(),
          },
        }));
      };
      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'SELL_RATE_UPDATE_SUCCESS') {
          setSellUpdatedAdsLoading(true);
          getAd();
        }
      };
      socket.onopen = handleOpen;
      socket.onmessage = handleMessage;
      // Cleanup function
      return () => {
        socket.onopen = null;
        socket.onmessage = null;
      };
    }
  }, [isConnected, token, getAd, setSellUpdatedAdsLoading]);

  return (
    <>
      <Center width="100%">
        <Box width={{ sm: "100%", md: "60%" }}>
          {
            loading && !sellUpdatedAdsLoading ? <LoadingAd /> : (ad !== null ? (
              <>
                <Box width="100%"> <Heading color="white" size="md"> Offer #{adId} </Heading></Box>
                 <VStack mt="5" background="brandScheme.500" padding="5" borderRadius={'5'}>
                  <Box width="100%">
                    <Flex>
                      <Heading
                        fontSize={{
                          base: "sm",
                          sm: "md",
                          md: "md",
                          lg: "lg",
                        }}
                        mt="2"
                        color="#ffffff">
                        Buy {ad.asset.symbol}
                      </Heading>
                      &nbsp;&nbsp;
                      {ad.network !== null && <Avatar src={NETWORKS[ad.network]['ICONURL']} size="sm" />}
                    </Flex>
                  </Box>

                  <Box width="100%">
                    <VStack width="100%" mt="5" spacing="2">
                      <Box width="100%" borderRadius={'10'} background="#000000" padding="5">
                        <FormControl>
                          <FormLabel color={'white'} fontWeight="bold">
                            I am paying
                          </FormLabel>
                          <InputGroup>
                            <NumberInput
                              width="100%"
                              value={buyData.amountFormatted}>
                              <NumberInputField
                                color="white"
                                height="45"
                                placeholder="0.00"
                                borderColor="transparent"
                                borderRadius="0"
                                required={true}
                                onChange={(e) => {
                                  const unit = e.target.value === "" ? 0 : parseFloat(e.target.value.replace(/,/g, '')) / (ad.currency.rateToDollar["$numberDecimal"] * ad.asset.rateToDollar["$numberDecimal"] * (ad.factor["$numberDecimal"] / 100));
                                  setBuyData(prevBuyData => ({
                                    ...prevBuyData,
                                    amount: e.target.value === "" ? "" : parseFloat(e.target.value.replace(/,/g, '')),
                                    amountFormatted: e.target.value === "" ? "" : parseFloat(e.target.value.replace(/,/g, '')),
                                    //new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(e.target.value.replace(/,/g, ''))),
                                    unit: unit,
                                    unitFormatted: ad.asset.symbol !== "USDT" ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 8 }).format(unit) : new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(unit)
                                  }));
                                }}
                              />
                            </NumberInput>
                            <InputRightAddon textAlign="center" borderWidth="0" background="secondary.500" color="white" height="45" width="70" borderRadius="0">{ad.currency.symbol}</InputRightAddon>
                          </InputGroup>
                        </FormControl>
                      </Box>
                      <Box width="100%" borderRadius={'10'} background="#000000" padding="5">
                        <FormControl>
                          <FormLabel color={'white'} fontWeight="bold">
                            You will receive
                          </FormLabel>
                          <InputGroup>
                            <NumberInput width="100%" value={buyData.unitFormatted}>
                              <NumberInputField
                                color="white"
                                height="45"
                                placeholder="0.00"
                                borderColor="transparent"
                                borderRadius="0"
                                required={true}
                                onChange={(e) => {
                                  const amount = e.target.value === "" ? 0 : parseFloat(e.target.value.replace(/,/g, '')) * (ad.currency.rateToDollar["$numberDecimal"] * ad.asset.rateToDollar["$numberDecimal"] * (ad.factor["$numberDecimal"] / 100));
                                  setBuyData(prevBuyData => ({
                                    ...prevBuyData,
                                    unit: e.target.value === "" ? "" : parseFloat(e.target.value.replace(/,/g, '')),
                                    unitFormatted: e.target.value === "" ? "" : e.target.value,
                                    amount: amount,
                                    amountFormatted: new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount),
                                  }));
                                }}
                              />
                            </NumberInput>
                            <InputRightAddon textAlign="center" borderWidth="0" background="secondary.500" color="white" height="45" width="70" borderRadius="0">{ad.asset.symbol}</InputRightAddon>
                          </InputGroup>
                        </FormControl>
                      </Box>
                    </VStack>

                    <Box width="100%" textAlign={"center"} mt="5">
                      <Button
                        size="lg"
                        width={"100%"}
                        colorScheme="secondary"
                        borderRadius="10"
                        isLoading={sellLoading}
                        loadingText="Please Wait"
                        onClick={handleCreateBuyTrade}>
                        Proceed
                      </Button>
                    </Box>
                  </Box>
                </VStack>

                <SimpleGrid columns={{ base: 1, sm: 1, md: 4 }} width="100%" spacing={{ sm: "5", md: "2" }} p="5" mt="10" background="brandScheme.500" borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500">
                  <Box textAlign="left">
                    <Link to={"/app/user/" + ad.owner.username}>
                      <Heading textDecoration="underline" fontSize={{ sm: "sm", md: "md" }} color="white">{ad.owner.username}</Heading>
                    </Link>
                    <Flex color="gray.400" gap='1'>
                      <Text textAlign="left" fontSize={{ sm: "sm", md: "md" }}>
                        {ad.owner.completedTrades} Orders
                      </Text>
                      <Text> | </Text>
                      {
                        (() => {
                          const totalTrades = (ad.owner.completedTrades === 0 ? 1 : ad.owner.completedTrades) + ad.owner.incompleteTrades;
                          const percentageCompletion = ((ad.owner.completedTrades === 0 ? 1 : ad.owner.completedTrades) * 100) / totalTrades;
                          return (
                            <>
                              <Text fontSize={{ sm: "sm", md: "md" }}> {percentageCompletion}% Completion</Text>
                            </>
                          )
                        })()
                      }
                    </Flex>
                    <Flex color="gray.400" gap='1'>
                      <Text textAlign="left">
                        <Flex>
                          <Text fontSize={{ sm: "sm", md: "md" }}>{ad.owner.positiveTrades}</Text>&nbsp;<Text mt="1"><FiThumbsUp /></Text>
                        </Flex>
                      </Text>
                      <Text textAlign="left">
                        <Flex>
                          <Text fontSize={{ sm: "sm", md: "md" }}>{ad.owner.negativeTrades}</Text>&nbsp;<Text mt="1"><FiThumbsDown /></Text>
                        </Flex>
                      </Text>
                    </Flex>
                  </Box>
                  <Box textAlign="left">
                    <Heading color="white" fontSize={{ sm: "sm", md: "md" }}>
                      {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ad.price["$numberDecimal"])} {ad.currency.symbol}
                    </Heading>
                    <Text color="gray.500" fontSize={{ sm: "sm", md: "md" }}>Price</Text>
                  </Box>

                  <Box textAlign="left">
                    <Heading color="white" fontSize={{ sm: "sm", md: "md" }}>
                      {ad.asset.symbol !== "USDT" ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 8 }).format(ad.balance) : new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(truncateNumber(ad.balance,2))}&nbsp;
                      {ad.asset?.symbol}
                    </Heading>
                    <Text color="gray.500" fontSize={{ sm: "sm", md: "md" }}>Balance</Text>
                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white" mt="2">
                      {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(ad.lowerLimit)} - {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(ad.upperLimit)} {ad.currency.symbol}
                    </Heading>
                    <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Limit</Text>
                  </Box>
                  <Box textAlign="left">
                    <Heading fontSize={{ sm: "sm", md: "md" }} color="white">{ad.paymentMethod?.paymentMethod?.name} </Heading>
                    <Text fontSize={{ sm: "sm", md: "md" }} color="gray.500">Payment Method</Text>
                  </Box>
                </SimpleGrid>


                <Tabs variant='soft-rounded' minH="200" colorScheme='green' mt="10" p="5" borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500">
                  <TabList>
                    <Tab background="brand.500" color="white" fontSize={{ sm: "sm", md: "md" }}>Trade Instruction</Tab>
                    <Tab background="brand.500" ml="2" color="white" fontSize={{ sm: "sm", md: "md" }}>Trader's Review</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel p="0">
                      <Box width="100%" mt="5" mb="5">
                        <Heading color="white" fontSize={{ sm: "md", lg: "lg" }}>
                           Trade Instruction
                        </Heading>
                      </Box>
                      <Box width="100%" mt="2"> <Text color="white" fontSize={{ sm: "sm", md: "md" }}>{ad.notes} </Text> </Box>
                    </TabPanel>

                    <TabPanel p="0">
                      <Box mt="5">
                        <Box width="100%"> <Heading color="white" fontSize={{ sm: "md", lg: "lg" }}> Trader's Review </Heading> </Box>
                        <Box width="100%" mt="3">
                        <VStack width="100%">
                        {
                          reviews !== null ? reviews.map((review) => (<><HStack spacing="3" width="100%"><TestimonialAvatar
                            src={""}
                            name={review.sender.username}
                          />
                            <Box>
                              <Text fontSize={'sm'} color='gray.400'>
                                {formatTime(review.createdAt)}
                              </Text>
                              <Text fontSize={'sm'} color='white'>
                                {review.comment}
                              </Text>
                              <Box>
                                <Rating rating={review.rating} />
                              </Box>
                            </Box></HStack></>
                          )) :
                            <LoadingReview />
                        }
                      </VStack>
                      <Box mt="2" width="100%" textAlign="center" color="white" textDecoration={"underline"}><Link to={"/app/user/" + username}>See All Reviews</Link></Box>

                        </Box>
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>

              </>) : "")
            }

          { (!isConnected || !token) && 
          <Center width="100%" height="300">
            <Text fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }} color="#ffffff">Please Connect Wallet</Text>
          </Center>
          }
        </Box>
      </Center>
    </>
  );
}

const TestimonialAvatar = ({
  src,
  name
}) => {
  return (
    <Flex align={'center'} mt={4} direction={'column'}>
      <Avatar src={''} alt={name} mb={2} name={name} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={400} color="white">{name}</Text>
      </Stack>
    </Flex>
  );
};

const Rating = ({ rating }) => {
  const filledStars = Math.floor(rating);
  const hasHalfStar = rating - filledStars >= 0.5;

  return (
    <Flex>
      {[...Array(5)].map((_, index) => (
        <Box key={index} color={index < filledStars ? "yellow.400" : "gray.600"}>
          {index < filledStars ? (
            <StarIcon boxSize={4} />
          ) : (
            hasHalfStar && index === filledStars && <StarIcon boxSize={4} />
          )}
        </Box>
      ))}
    </Flex>
  );
};

const LoadingAd = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};

const LoadingReview = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="5"
        mb="5"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
