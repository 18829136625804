import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Box,
    Heading,
    Text,
    Button,
    SimpleGrid,
    HStack,
    Flex,
    Avatar,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Input,
    InputGroup,
    InputRightElement,
    useClipboard,
    Spinner,
    useDisclosure,
    useMediaQuery,
    useToast
} from "@chakra-ui/react";
import { FiThumbsUp, FiThumbsDown, FiCopy, FiShare2, FiLink2, FiEye, FiEyeOff, FiEdit, FiDelete } from "react-icons/fi";
import { FacebookShare, TwitterShare, LinkedinShare, WhatsappShare, TelegramShare, EmailShare } from 'react-share-kit';
import NETWORKS from "contracts/Networks.json";
//import Card from "components/card/Card.js";
export default function SellAd(props) {
    const location = useLocation();
    const toast = useToast();
    const { isOpen: isShareOpen, onOpen: onShareOnOpen, onClose: onShareClose } = useDisclosure();
    const [shareUrl, setShareUrl] = useState('');
    const [copied, setCopied] = useState(false);
    const [title, setTitle] = useState('');
    const [hashtags, setHashtags] = useState([]);
    const { onCopy, hasCopied } = useClipboard(shareUrl);
    const { ad, handleUpdateSellAd, onOpen, setAdId, setAdType, setFundAdData, onFundAdOnOpen } = props;
    const [isMdOrSm] = useMediaQuery("(max-width: 960px)");

    useEffect(() => {
        if (shareUrl === "") return;
        onCopy();
    }, [shareUrl]);


    return (
        <>
            <Modal isOpen={isShareOpen} onClose={onShareClose}>
                <ModalOverlay />
                <ModalContent background="brand.500">
                    <ModalHeader color="white">Share Offer</ModalHeader>
                    <ModalCloseButton color="white" />
                    <ModalBody>
                        <Box width="100%" mb="10">
                            <Flex gap="2" flexDirection="row" flexWrap="wrap">
                                <FacebookShare url={shareUrl}
                                    quote={title}
                                    hashtag={hashtags} />
                                <TwitterShare
                                    url={shareUrl}
                                    title={title}
                                    hashtags={hashtags} />
                                <LinkedinShare
                                    url={shareUrl} />
                                <WhatsappShare
                                    url={shareUrl}
                                    title={title}
                                    separator=":: "
                                />
                                <TelegramShare url={shareUrl} />
                                <EmailShare
                                    url={shareUrl}
                                    subject={title}
                                    body="You can trade with me by visiting my Trade page"
                                />
                                <Link to={shareUrl}><FiLink2 mt="2" size="50" color="white" /></Link>
                            </Flex>
                            <InputGroup mt="4">
                                <Input color="white" value={shareUrl} isReadOnly />
                                <InputRightElement>
                                    <Button onClick={(e) => { setCopied(true) }} size="sm">
                                        <FiCopy />
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Text textAlign={'center'}>{copied && hasCopied ? 'Copied' : ''}</Text>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>


            <HStack width="100%" p="5" background="brandScheme.500" borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500">
                <Box width="90%">
                    <SimpleGrid columns={{ base: 1, sm: 1, md: 5 }} width="100%" spacing={{ sm: "5", md: "2" }}>
                        <Box textAlign="left">
                            <Link to={"/app/user/" + ad.owner.username}>
                                <Heading textDecoration="underline" fontSize={{ sm: "sm", md: "md" }} color="white">{ad.owner.username}</Heading>
                            </Link>
                            <Flex color="gray.400" gap='1'>
                                <Text textAlign="left" fontSize={{ sm: "sm", md: "md" }}>
                                    {ad.owner.completedTrades} Orders
                                </Text>
                                <Text> &nbsp; </Text>
                                {
                                    (() => {
                                        const totalTrades = (ad.owner.completedTrades === 0 ? 1 : ad.owner.completedTrades) + ad.owner.incompleteTrades;
                                        const percentageCompletion = ((ad.owner.completedTrades === 0 ? 1 : ad.owner.completedTrades) * 100) / totalTrades;
                                        return (
                                            <>
                                                <Text fontSize={{ sm: "sm", md: "md" }}> {percentageCompletion}% Completion</Text>
                                            </>
                                        )
                                    })()
                                }
                            </Flex>
                            <Flex color="gray.400" gap='1'>
                                <Text textAlign="left">
                                    <Flex>
                                        <Text fontSize={{ sm: "sm", md: "md" }}>{ad.owner.positiveTrades}</Text>&nbsp;<Text mt="1"><FiThumbsUp /></Text>
                                    </Flex>
                                </Text>
                                <Text textAlign="left">
                                    <Flex>
                                        <Text fontSize={{ sm: "sm", md: "md" }}>{ad.owner.negativeTrades}</Text>&nbsp;<Text mt="1"><FiThumbsDown /></Text>
                                    </Flex>
                                </Text>
                            </Flex>
                        </Box>
                        <Box textAlign="left">
                            {isMdOrSm && (
                                <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Price</Text>
                            )}
                            <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                                {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ad.price["$numberDecimal"])} {ad.currency.symbol}
                            </Heading>
                        </Box>

                        <Box textAlign="left">
                            {isMdOrSm && (
                                <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Limit</Text>
                            )}
                            <Flex>
                                <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                                    {ad.asset.symbol !== "USDT" ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 8 }).format(ad.unit["$numberDecimal"]) : new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ad.unit["$numberDecimal"])}  {ad.asset.symbol}
                                </Heading>
                                &nbsp;
                                {ad.network !== null && <Avatar src={NETWORKS[ad.network]['ICONURL']} size="xs"/>}
                            </Flex>
                            <Heading fontSize={{ sm: "sm", md: "md" }} color="white">
                                {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(ad.lowerLimit)} - {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(ad.upperLimit)} {ad.currency.symbol}
                            </Heading>
                        </Box>
                        <Box textAlign="left">
                            {isMdOrSm && (
                                <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Payment Method</Text>
                            )}
                            <Heading fontSize={{ sm: "sm", md: "md" }} color="white">{ad.paymentMethod?.paymentMethod?.name} </Heading>
                        </Box>
                        <Box textAlign="left">
                            {isMdOrSm && (
                                <Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}> Available Order</Text>
                            )}
                            <Flex>
                                <Text fontSize={{ sm: "sm", md: "md" }} color="white" textDecoration={'underline'}
                                    onClick={() => {
                                        setFundAdData({
                                            symbol: ad.asset.symbol,
                                            advertID: ad._id,
                                        });
                                        onFundAdOnOpen();
                                    }}>
                                    {ad.asset.symbol !== "USDT" ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 8 }).format(ad.balance) : new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ad.balance)}&nbsp;
                                    {ad.asset?.symbol}</Text> &nbsp;
                                {ad.createdOnBlockchain && !ad.initialFunded && <Spinner mt="1" size='sm' color="white" />}
                            </Flex>
                        </Box>
                    </SimpleGrid>
                </Box>
                <Box width="10%" textAlign="center" color="gray.400">
                    <Flex color="white" gap="3" flexDirection={{ base: "column", sm: "column", md: "row" }} >
                        {
                            ad.status === "active" ?
                                <FiEye color="gray.400" size="20" onClick={() => {
                                    handleUpdateSellAd({ status: "paused" }, ad._id);
                                }} /> :
                                <FiEyeOff color="gray.400" size="20" onClick={() => {
                                    handleUpdateSellAd({ status: "active" }, ad._id);
                                }} />
                        }
                        <Link
                            to={{ pathname: '/app/ads/edit/sell/' + ad._id }}>
                            <FiEdit color="gray.400" size="20" />
                        </Link>
                        <FiDelete color="gray.400" size="20" onClick={() => {
                            setAdId(ad._id);
                            setAdType("sell");
                            onOpen();
                        }} />
                        <FiShare2 color="gray.400" size="20" onClick={() => {
                            setShareUrl(process.env.REACT_APP_SITE_URL + "/app/ads/selloffer/" + ad._id);
                            setTitle(ad._id);
                            onShareOnOpen();
                        }} />
                    </Flex>
                </Box>
            </HStack>
        </>
    );
};
