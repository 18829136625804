import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Center,
  Avatar,
  Heading,
  VStack,
  Skeleton,
  SkeletonText,
  HStack,
  Stack,
  SimpleGrid,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tag,
  useToast,
  useMediaQuery
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetUserByUsername, GetUserRatings } from "services/userService";
import { GetUserSellAds, GetUserBuyAds } from "services/adService";
import BuyAd from "./components/BuyAd.js";
import SellAd from "./components/SellAd.js";
import { StarIcon } from "@chakra-ui/icons";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { MdVerified } from "react-icons/md";
import Paginate from "components/navigation/Paginate.js";
const { DateTime } = require("luxon");

export default function Index() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const [ratingLoading, setRatingLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [ratings, setRatings] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(12);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [finished, setFinished] = useState(false);
  const [reviews, setReviews] = useState([]);

  const [buyAdsLoading, setBuyAdsLoading] = useState(false);
  const [sellAdsLoading, setSellAdsLoading] = useState(false);
  const [buyAds, setBuyAds] = useState(null);
  const [sellAds, setSellAds] = useState(null);
  const [isMdOrSm] = useMediaQuery("(max-width: 960px)");

  const { username } = useParams();

  const getUser = async () => {
    try {
      setLoading(true);
      const response = await GetUserByUsername(username);
      setLoading(false);
      if (!response) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.error) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success === true) {
        setUser(response.data);
        setUserId(response.data._id);
      }
    } catch (error) {
      setUser(null);
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getSellAds = async () => {
    try {
      setSellAdsLoading(true);
      const filter = { status: "active" };
      const response = await GetUserSellAds(userId, filter, token);
      setSellAdsLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setSellAds(response.data);
        return;
      }
    } catch (error) {
      setSellAdsLoading(false);
    }
  };

  const getBuyAds = async () => {
    try {
      setBuyAdsLoading(true);
      const filter = { status: "active" };
      const response = await GetUserBuyAds(userId, filter, token);
      setBuyAdsLoading(false);
      if (!response) {
        return;
      }
      if (response.error) {
        return;
      }
      if (response.success === true) {
        setBuyAds(response.data);
        return;
      }
    } catch (error) {
      setBuyAdsLoading(false);
    }
  };

  const getUserReviews = async () => {
    try {
      setRatingLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = { owner: userId };
      const response = await GetUserRatings(pagination, filter, token);
      setRatingLoading(false);
      if (response.error) {
        toast({
          title: response.messagse,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }

      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setReviews(response.data.ratings);
        setPageCount(response.data.count / limit);
        if (response.data?.ratings.length === 0) {
          setFinished(true);
        }
      }
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setRatingLoading(false);
    }
  };

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATE_MED);
  };

  function formatRelativeDate(dateString) {
    const date = new Date(dateString);
    const diff = Date.now() - date.getTime();
    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days < 30) {
      return `${days} days ago`;
    } else if (days < 365) {
      return `${Math.floor(days / 30)} months ago`;
    } else {
      return `${Math.floor(days / 365)} years ago`;
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (userId !== null) {
      getSellAds();
      getBuyAds();
      getUserReviews();
    } else {
      setSellAds(null);
      setBuyAds(null);
      setReviews([]);
    }
  }, [userId]);

  return (
    <>
      <Box pt={{ base: "30px", md: "40px", xl: "40px" }} width="100%" pb="20px" p={{sm: 2, md: 4 }}>
        <Box width="100%" mt="5" mb="5">
          <Flex>
            <Box>
              <Avatar size='lg' name={user !== null && user.username} />
            </Box>
            <Box ml="5">
              <Flex><Heading color="white" size="md">{user !== null && user.username}</Heading>&nbsp;&nbsp;{user !== null && user.kycVerified ? <MdVerified color="white" size="20" /> : <MdVerified color="#707EAE" size="20" />} </Flex>
              <Box textAlign={"center"}>
                <Flex color="gray.400" gap='1'>
                  {user !== null ? <Text textAlign="left" color="#ffffff" fontSize={{ sm: "md", md: "lg" }}> {user.completedTrades} Orders</Text> : <Center><SkeletonText mt="2" noOfLines={1} skeletonHeight='2' width="25px"></SkeletonText></Center>}
                  {user !== null && <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}> | </Text>}
                  {
                    user !== null ?
                      (() => {
                        const totalTrades = (user.completedTrades === 0 ? 1 : user.completedTrades) + user.incompleteTrades;
                        const percentageCompletion = ((user.completedTrades === 0 ? 1 : user.completedTrades) * 100) / totalTrades;
                        return (
                          <>
                            <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}> {percentageCompletion}% Completion</Text>
                          </>
                        )
                      })()
                      :
                      <SkeletonText />
                  }
                </Flex>
                <Flex color="gray.400" gap='1'>
                  <Text textAlign="center">
                    <Flex>
                      {user !== null ? <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>{user.positiveTrades}</Text > : <SkeletonText />}&nbsp;<Text mt="1"><FiThumbsUp color="#ffffff" size="16" /></Text>
                    </Flex>
                  </Text>
                  <Text textAlign="left">
                    <Flex>
                      {user !== null ? <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>{user.negativeTrades}</Text> : <SkeletonText />} &nbsp;<Text mt="1"><FiThumbsDown color="#ffffff" size="16" /></Text>
                    </Flex>
                  </Text>
                </Flex>
              </Box>

              <Flex gap='2' mt="2">
                <Box>
                  <Flex gap='2' flexDirection={{ base: "column", sm: "column", md: "row" }}><Text color="gray.500">Account Created</Text>{user !== null ? <Text color="white">{formatRelativeDate(user.createdAt)}</Text> : <SkeletonText />}</Flex>
                </Box>
                <Box>
                  <Flex gap='2' flexDirection={{ base: "column", sm: "column", md: "row" }}><Text color="gray.500">Last seen</Text>{user !== null ? <Text color="white">{formatRelativeDate(user.lastSeen)}</Text> : <SkeletonText />}</Flex>
                </Box>
                <Box>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>


        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3, lg: 3 }}
          spacing={2}
          mb="4"
        >
          <Box minHeight="100" borderRadius={"5"} background="brand.500">
            <Box minHeight="100" width="100%" pt="3">
              <Text color="white" textAlign="center" fontSize={{ sm: "sm", md: "md" }}>
                {user !== null ? <Heading size="lg">{user.completedTrades}</Heading> : <Center><SkeletonText mt="2" noOfLines={1} skeletonHeight='2' width="25px"></SkeletonText></Center>}
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center" fontSize={{ sm: "sm", md: "md" }}>
                Number of Completed Trades
              </Text>
            </Box>
          </Box>

          <Box minHeight="100" borderRadius={"5"} background="brand.500">
            <Box minHeight="100" width="100%" pt="3">
              <Text color="white" textAlign="center" fontSize={{ sm: "sm", md: "md" }}>
                {user !== null ? <Heading size="lg">{(user.completedTrades + user.incompleteTrades)}</Heading> : <Center><SkeletonText mt="2" noOfLines={1} skeletonHeight='2' width="25px"></SkeletonText></Center>}
              </Text>
              <Text color="white" fontWeight="bold" textAlign="center" fontSize={{ sm: "sm", md: "md" }}>
                Total Trading Partners
              </Text>
            </Box>
          </Box>
        </SimpleGrid>


        <Tabs variant='soft-rounded' colorScheme='green' mt="10" p="0">
          <TabList>
            <Tab background="brand.500" color="white">Offers</Tab>
            <Tab background="brand.500" ml="2" color="white">Feedbacks</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0">
              <Box width="100%" mt="5">
                <Heading fontSize={{ sm: "md", md: "xl" }} color="secondary.500">
                  Buy Offers
                </Heading>
              </Box>
              {buyAdsLoading ? (
                <LoadingAds />
              ) : (
                <VStack
                  width="100%"
                  mb="2"
                  spacing="3"
                >
                  {!isMdOrSm && (
                    <HStack width="100%" p="5">
                      <Box width="90%">
                        <SimpleGrid columns={{ base: 1, sm: 2, md: 5 }} width="100%" spacing="2">
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Merchant</Text></Box>
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Price</Text></Box>
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Available Order | Limit</Text></Box>
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Payment Method</Text></Box>
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Balance</Text></Box>
                        </SimpleGrid>
                      </Box>
                      <Box width="10%">
                        <Text fontWeight="bold" color="gray.400">Action</Text>
                      </Box>
                    </HStack>)}
                  {
                    buyAds !== null && buyAds.map((ad) => <BuyAd ad={ad} />)
                  }
                </VStack>
              )}

              <Box width="100%" mt="10">
                <Heading fontSize={{ sm: "md", md: "xl" }} color="secondary.500">
                  Sell Offers
                </Heading>
              </Box>
              {sellAdsLoading ? (
                <LoadingAds />
              ) : (
                <VStack
                  width="100%"
                  mb="2"
                  spacing="3"
                >
                  {!isMdOrSm && (
                    <HStack width="100%" p="5">
                      <Box width="90%">
                        <SimpleGrid columns={{ base: 1, sm: 2, md: 5 }} width="100%" spacing="2">
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Merchant</Text></Box>
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Price</Text></Box>
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Available Order | Limit</Text></Box>
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Payment Method</Text></Box>
                          <Box><Text fontWeight="bold" color="gray.400" fontSize={{ sm: "sm", md: "md" }}>Balance</Text></Box>
                        </SimpleGrid>
                      </Box>
                      <Box width="10%">
                        <Text fontWeight="bold" color="gray.400">Action</Text>
                      </Box>
                    </HStack>
                  )}
                  {
                    sellAds !== null && sellAds.map((ad) => <SellAd ad={ad} />)
                  }
                </VStack>
              )}
            </TabPanel>

            <TabPanel p="0">
              <Box mt="5">
                <Box width="100%"> <Heading color="white" size={{sm: "sm", md: "md"}}> Trader's Review </Heading> </Box>
                <Box width="100%" mt="3">
                  <VStack width="100%" spacing="5">
                    {
                      ratingLoading ? <LoadingReview /> : (
                        reviews !== null && reviews.map((review) => (<>
                          <Box key={review._id} spacing="3" p="2" width="100%" background="brandScheme.500" borderRadius={'5'} borderLeftWidth="2px" borderLeftColor="secondary.500">
                            <Flex gap="3">
                              <TestimonialAvatar
                                src={
                                  ''
                                }
                                name={review.sender.username}
                              />
                              <Box>
                                <Text fontSize={{ sm: "sm", md: "md" }} color='gray.400'>
                                  {formatTime(review.createdAt)}
                                </Text>
                                <Text fontSize={{ sm: "sm", md: "md" }} color='white'>
                                  {review.comment}
                                </Text>
                                <Box>
                                  <Rating rating={review.rating} />
                                </Box>
                              </Box>
                            </Flex></Box></>
                        ))
                      )
                    }
                  </VStack>

                  {pageCount > 0 && (
                    <Box width="100%" mt="5">
                      <Paginate
                        pageCount={pageCount}
                        setFrom={setFrom}
                        getRecords={getUserReviews}
                      />
                    </Box>
                  )}

                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}

const TestimonialAvatar = ({
  src,
  name
}) => {
  return (
    <Flex align={'center'} mt={3} direction={'column'}>
      <Avatar src={src} alt={name} mb={2}  name={name} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={400} color="white">{name}</Text>
      </Stack>
    </Flex>
  );
};

const Rating = ({ rating }) => {
  const filledStars = Math.floor(rating);
  const hasHalfStar = rating - filledStars >= 0.5;

  return (
    <Flex>
      {[...Array(5)].map((_, index) => (
        <Box key={index} color={index < filledStars ? "yellow.400" : "gray.600"}>
          {index < filledStars ? (
            <StarIcon boxSize={4} />
          ) : (
            hasHalfStar && index === filledStars && <StarIcon boxSize={4} />
          )}
        </Box>
      ))}
    </Flex>
  );
};

const LoadingAds = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="10"
        mb="20"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};


const LoadingReview = () => {
  return (
    <>
      <SimpleGrid
        width="100%"
        mt="5"
        mb="5"
        columns={{ sm: "1", md: "2", lg: "3" }}
        spacing="2"
      >
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
        <Box>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Box>
      </SimpleGrid>
    </>
  );
};
